var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: _vm.question.cssClasses.root,
    domProps: { innerHTML: _vm._s(_vm.question.locHtml.renderedHtml) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }