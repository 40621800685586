
















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { Question } from "survey-core";
import { QuestionMultipleTextModel } from "survey-core";

@Component
export class MultipleText extends QuestionVue<QuestionMultipleTextModel> {
  getComponentName(question: Question) {
    if (question.customWidget) {
      return "survey-customwidget";
    }
    return "survey-text";
  }
  get hasErrorsOnTop() {
    return this.question.survey.questionErrorLocation === "top";
  }
  get hasErrorsOnBottom() {
    return this.question.survey.questionErrorLocation === "bottom";
  }
}
Vue.component("survey-multipletext", MultipleText);
export default MultipleText;
