var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { class: _vm.question.cssClasses.root },
    [
      _c("legend", {
        attrs: { role: "checkbox", "aria-label": _vm.question.ariaLabel },
      }),
      _vm._v(" "),
      _vm._l(_vm.question.visibleChoices, function (item, index) {
        return !_vm.question.hasColumns
          ? _c("survey-checkbox-item", {
              key: item.value,
              class: _vm.question.getItemClass(item),
              attrs: { question: _vm.question, item: item, index: index },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm._l(_vm.question.columns, function (column, colIndex) {
        return _vm.question.hasColumns
          ? _c(
              "div",
              { class: _vm.question.getColumnClass() },
              _vm._l(column, function (item, index) {
                return _c("survey-checkbox-item", {
                  key: item.value,
                  class: _vm.question.getItemClass(item),
                  attrs: {
                    question: _vm.question,
                    item: item,
                    index: "" + colIndex + index,
                  },
                })
              }),
              1
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }