var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.model.needFilter
      ? _c("input", {
          staticClass: "sv-list__input",
          attrs: {
            type: "text",
            placeholder: _vm.model.filteredTextPlaceholder,
          },
          domProps: { value: _vm.model.filteredText },
          on: { change: _vm.change, keyup: _vm.keyup },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "sv-list",
        on: {
          mousedown: function (event) {
            event.preventDefault()
          },
          keydown: function (event) {
            _vm.model.onKeyDown(event)
          },
        },
      },
      _vm._l(_vm.model.renderedActions, function (item) {
        return _c("sv-list-item", {
          key: item.id,
          attrs: { item: item, model: _vm.model },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }