var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { overflowX: _vm.question.horizontalScroll ? "scroll" : "" } },
    [_c("survey-matrixtable", { attrs: { question: _vm.question } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }