var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.item.visible,
          expression: "item.visible",
        },
      ],
      key: _vm.item.id,
      class: _vm.model.getItemClass(_vm.item),
      style: { paddingLeft: _vm.model.getItemIndent(_vm.item) },
      attrs: { tabindex: "0" },
      on: {
        click: function ($event) {
          return _vm.model.selectItem(_vm.item)
        },
      },
    },
    [
      _vm.item.iconName && !_vm.item.component
        ? _c("sv-svg-icon", {
            staticClass: "sv-list__item-icon",
            attrs: { iconName: _vm.item.iconName, size: 24 },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.component
        ? _c("span", [_vm._v(_vm._s(_vm.item.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.component
        ? _c(_vm.item.component, {
            tag: "component",
            attrs: { item: _vm.item },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }