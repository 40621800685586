var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { style: _vm.style },
    [
      !_vm.question
        ? _c(
            _vm.tagName,
            { tag: "component" },
            [
              _vm._l(_vm.nodes, function (elNode) {
                return _c("survey-flowpanelelement", {
                  key: elNode.elementId,
                  attrs: { node: elNode, panel: _vm.panel, css: "css" },
                })
              }),
              _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.question
        ? _c(
            "span",
            [
              _c("survey-element", {
                key: _vm.question.idValue,
                style: {
                  flexBasis: _vm.question.renderWidth,
                  flexGrow: 1,
                  flexShrink: 1,
                  width: _vm.question.renderWidth,
                  minWidth: _vm.question.minWidth,
                  maxWidth: _vm.question.maxWidth,
                },
                attrs: {
                  id: _vm.question.id,
                  element: _vm.question,
                  survey: _vm.survey,
                  css: _vm.css,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }