





























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionCheckboxModel } from "survey-core";

@Component
export class Checkbox extends QuestionVue<QuestionCheckboxModel> {
}
Vue.component("survey-checkbox", Checkbox);
export default Checkbox;
