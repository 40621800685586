



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyElementCore, doKey2ClickUp } from "survey-core";

@Component
export class TitleElement extends Vue {
  @Prop() element: SurveyElementCore;
  @Prop() css: any;

  keyup(evt: any) {
    doKey2ClickUp(evt);
  }
}
Vue.component("survey-element-title", TitleElement);
export default TitleElement;
