var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-element-title-content", {
        attrs: { element: _vm.element, css: _vm.css },
      }),
      _vm._v(" "),
      _c("span", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIcon,
            expression: "showIcon",
          },
        ],
        class: _vm.getIconCss(_vm.isCollapsed),
        attrs: {
          tabindex: "0",
          "aria-expanded": _vm.isCollapsed ? "false" : "true",
          "aria-controls": !_vm.isCollapsed ? _vm.element.contentId : null,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.changeExpanded.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }