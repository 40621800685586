





































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { VueSurveyModel as SurveyModel } from "./surveyModel";

@Component
export class SurveyHeader extends Vue {
  @Prop() survey: SurveyModel;

  mounted() {
    var el = this.$el;
    if (el && this.survey) this.survey.doAfterRenderHeader(el);
  }
}

Vue.component("survey-header", SurveyHeader);
export default SurveyHeader;
