var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticStyle: { position: "static" } }, [
    _vm.locString.hasHtml
      ? _c("span", {
          staticClass: "sv-string-viewer",
          staticStyle: { position: "static" },
          domProps: { innerHTML: _vm._s(_vm.locString.renderedHtml) },
        })
      : _c(
          "span",
          {
            staticClass: "sv-string-viewer",
            staticStyle: { position: "static" },
          },
          [_vm._v(_vm._s(_vm.locString.renderedHtml))]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }