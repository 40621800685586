var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.question.getDetailPanelButtonCss(_vm.row),
      attrs: {
        type: "button",
        "aria-expanded": _vm.question.getIsDetailPanelShowing(_vm.row)
          ? "true"
          : "false",
        "aria-controls": _vm.question.getIsDetailPanelShowing(_vm.row)
          ? _vm.row.detailPanelId
          : null,
      },
      on: {
        click: function () {
          return _vm.row.showHideDetailPanelClick()
        },
      },
    },
    [_c("span", { class: _vm.question.getDetailPanelIconCss(_vm.row) })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }