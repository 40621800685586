






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyElement } from "survey-core";

@Component
export class Errors extends Vue {
  @Prop() element: SurveyElement;
  @Prop() location: String;
}
Vue.component("survey-errors", Errors);
export default Errors;
