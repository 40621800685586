var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.tableWrapper }, [
    _c("fieldset", [
      _c("legend", {
        attrs: { "aria-label": _vm.question.locTitle.renderedHtml },
      }),
      _vm._v(" "),
      _c("table", { class: _vm.question.cssClasses.root }, [
        _vm.question.showHeader
          ? _c("thead", [
              _c(
                "tr",
                [
                  _c("td", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question.hasRows,
                        expression: "question.hasRows",
                      },
                    ],
                  }),
                  _vm._v(" "),
                  _vm._l(
                    _vm.question.visibleColumns,
                    function (column, columnIndex) {
                      return _c(
                        "th",
                        {
                          key: columnIndex,
                          class: _vm.question.cssClasses.headerCell,
                        },
                        [
                          _c("survey-string", {
                            attrs: { locString: column.locText },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.question.visibleRows, function (row, rowIndex) {
            return _c(
              "tr",
              {
                key: "row_" + row.name + "_" + rowIndex,
                class: row.rowClasses,
              },
              [
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question.hasRows,
                        expression: "question.hasRows",
                      },
                    ],
                    class: _vm.question.cssClasses.cell,
                  },
                  [_c("survey-string", { attrs: { locString: row.locText } })],
                  1
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.question.visibleColumns,
                  function (column, columnIndex) {
                    return _vm.question.hasCellText
                      ? _c(
                          "td",
                          {
                            key: columnIndex,
                            class: _vm.question.getItemClass(row, column),
                            on: {
                              click: function ($event) {
                                return _vm.cellClick(row, column)
                              },
                            },
                          },
                          [
                            _c("survey-string", {
                              attrs: {
                                locString: _vm.question.getCellDisplayLocText(
                                  row.name,
                                  column
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.question.visibleColumns,
                  function (column, columnIndex) {
                    return !_vm.question.hasCellText
                      ? _c(
                          "td",
                          {
                            key: columnIndex,
                            class: _vm.question.cssClasses.cell,
                            attrs: { title: column.locText.renderedHtml },
                            on: {
                              click: function ($event) {
                                return _vm.cellClick(row, column)
                              },
                            },
                          },
                          [
                            _c(
                              "label",
                              { class: _vm.question.getItemClass(row, column) },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.value,
                                      expression: "row.value",
                                    },
                                  ],
                                  class: _vm.question.cssClasses.itemValue,
                                  attrs: {
                                    type: "radio",
                                    name: row.fullName,
                                    disabled: _vm.question.isInputReadOnly,
                                    id:
                                      _vm.question.inputId +
                                      "_" +
                                      row.name +
                                      "_" +
                                      columnIndex,
                                    "aria-required": _vm.question.ariaRequired,
                                    "aria-label": _vm.question.ariaLabel,
                                    "aria-invalid": _vm.question.ariaInvalid,
                                    "aria-describedby":
                                      _vm.question.ariaDescribedBy,
                                  },
                                  domProps: {
                                    value: column.value,
                                    checked: _vm._q(row.value, column.value),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        row,
                                        "value",
                                        column.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.question.cssClasses.materialDecorator,
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        class:
                                          _vm.question.cssClasses.itemDecorator,
                                        attrs: { viewBox: "-12 -12 24 24" },
                                      },
                                      [
                                        _c("circle", {
                                          attrs: { r: "6", cx: "0", cy: "0" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "circle" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "check" }),
                                _vm._v(" "),
                                _c("span", { style: { display: "none" } }, [
                                  _vm._v(
                                    _vm._s(_vm.question.locTitle.renderedHtml)
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e()
                  }
                ),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }