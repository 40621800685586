





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, ListModel } from "survey-core";
import { BaseVue } from "../../base";

export * from "./list.vue";

@Component
export class ListItem extends BaseVue {
  @Prop() item: Base;
  @Prop() model: ListModel;
  constructor() {
    super();
  }
  getModel() {
    return this.item;
  }
}

Vue.component("sv-list-item", ListItem);
export default ListItem;
