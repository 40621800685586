var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { class: _vm.question.getLabelClass(_vm.item) }, [
        _vm.item == _vm.question.selectAllItem
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAllSelected,
                  expression: "isAllSelected",
                },
              ],
              class: _vm.question.cssClasses.itemControl,
              attrs: {
                type: "checkbox",
                name: _vm.question.name,
                id: _vm.question.getItemId(_vm.item),
                disabled: !_vm.question.getItemEnabled(_vm.item),
                "aria-required": _vm.question.ariaRequired,
                "aria-label": _vm.question.ariaLabel,
                "aria-invalid": _vm.question.ariaInvalid,
                "aria-describedby": _vm.question.ariaDescribedBy,
              },
              domProps: {
                value: _vm.isAllSelected,
                checked: Array.isArray(_vm.isAllSelected)
                  ? _vm._i(_vm.isAllSelected, _vm.isAllSelected) > -1
                  : _vm.isAllSelected,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isAllSelected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.isAllSelected,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isAllSelected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isAllSelected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isAllSelected = $$c
                  }
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.item != _vm.question.selectAllItem
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.question.renderedValue,
                  expression: "question.renderedValue",
                },
              ],
              class: _vm.question.cssClasses.itemControl,
              attrs: {
                type: "checkbox",
                name: _vm.question.name,
                id: _vm.question.getItemId(_vm.item),
                disabled: !_vm.question.getItemEnabled(_vm.item),
                "aria-required": _vm.question.ariaRequired,
                "aria-label": _vm.question.ariaLabel,
                "aria-invalid": _vm.question.ariaInvalid,
                "aria-describedby": _vm.question.ariaDescribedBy,
              },
              domProps: {
                value: _vm.item.value,
                checked: Array.isArray(_vm.question.renderedValue)
                  ? _vm._i(_vm.question.renderedValue, _vm.item.value) > -1
                  : _vm.question.renderedValue,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.question.renderedValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.item.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.question,
                          "renderedValue",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.question,
                          "renderedValue",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.question, "renderedValue", $$c)
                  }
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", { class: _vm.question.cssClasses.materialDecorator }, [
          _c(
            "svg",
            {
              class: _vm.question.cssClasses.itemDecorator,
              attrs: { viewBox: "0 0 24 24" },
            },
            [_c("path", { attrs: { d: _vm.question.checkBoxSvgPath } })]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "check" }),
        ]),
        _vm._v(" "),
        !_vm.hideLabel
          ? _c(
              "span",
              {
                class: _vm.question.cssClasses.controlLabel,
                attrs: { title: _vm.item.locText.text },
              },
              [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.question.isOtherItem(_vm.item)
        ? _c("survey-other-choice", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.question.renderedValue && _vm.question.isOtherSelected,
                expression:
                  "\n      question.renderedValue && question.isOtherSelected\n    ",
              },
            ],
            attrs: { question: _vm.question },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }