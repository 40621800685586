
























































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionFileModel } from "survey-core";
import { confirmAction, detectIEOrEdge, loadFileFromBase64 } from "survey-core";
@Component
export class File extends QuestionVue<QuestionFileModel> {
  doRemoveFile(data: any) {
    this.question.doRemoveFile(data);
  }

}
Vue.component("survey-file", File);
export default File;
