var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { class: _vm.question.cssClasses.root },
    [
      _c("legend", {
        attrs: {
          role: "radio",
          "aria-label": _vm.question.locTitle.renderedHtml,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.question.visibleChoices, function (item, index) {
        return _c("div", { key: item.value, class: _vm.getItemClass(item) }, [
          _c("label", { class: _vm.question.cssClasses.label }, [
            _vm.question.multiSelect
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.question.value,
                      expression: "question.value",
                    },
                  ],
                  class: _vm.question.cssClasses.itemControl,
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "checkbox",
                    name: _vm.question.name + "_" + _vm.question.id,
                    id: _vm.question.inputId + "_" + item.value,
                    disabled: _vm.question.isInputReadOnly || !item.isEnabled,
                    "aria-required": _vm.question.isRequired,
                    "aria-label": _vm.question.ariaLabel,
                    "aria-invalid": _vm.question.ariaInvalid,
                    "aria-describedby": _vm.question.ariaDescribedBy,
                  },
                  domProps: {
                    value: item.value,
                    checked: Array.isArray(_vm.question.value)
                      ? _vm._i(_vm.question.value, item.value) > -1
                      : _vm.question.value,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.question.value,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = item.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.question, "value", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.question,
                              "value",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.question, "value", $$c)
                      }
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.question.value,
                      expression: "question.value",
                    },
                  ],
                  class: _vm.question.cssClasses.itemControl,
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "radio",
                    name: _vm.question.name + "_" + _vm.question.id,
                    id: _vm.question.inputId + "_" + item.value,
                    disabled: _vm.question.isInputReadOnly || !item.isEnabled,
                    "aria-required": _vm.question.isRequired,
                    "aria-label": _vm.question.ariaLabel,
                    "aria-invalid": _vm.question.ariaInvalid,
                    "aria-describedby": _vm.question.ariaDescribedBy,
                  },
                  domProps: {
                    value: item.value,
                    checked: _vm._q(_vm.question.value, item.value),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.question, "value", item.value)
                    },
                  },
                }),
            _vm._v(" "),
            _c("div", [
              _vm.question.contentMode === "image"
                ? _c("img", {
                    class: _vm.question.cssClasses.image,
                    style: { objectFit: _vm.question.imageFit },
                    attrs: {
                      src: item.imageLink,
                      width: _vm.question.imageWidth
                        ? _vm.question.imageWidth + "px"
                        : undefined,
                      height: _vm.question.imageHeight
                        ? _vm.question.imageHeight + "px"
                        : undefined,
                      alt: item.text || item.value,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.question.contentMode === "video"
                ? _c("embed", {
                    class: _vm.question.cssClasses.image,
                    style: { objectFit: _vm.question.imageFit },
                    attrs: {
                      src: item.imageLink,
                      width: _vm.question.imageWidth
                        ? _vm.question.imageWidth + "px"
                        : undefined,
                      height: _vm.question.imageHeight
                        ? _vm.question.imageHeight + "px"
                        : undefined,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.question.showLabel
                ? _c(
                    "span",
                    {
                      class: _vm.question.cssClasses.itemText,
                      attrs: { title: item.text || item.value },
                    },
                    [_vm._v(_vm._s(item.text || item.value))]
                  )
                : _vm._e(),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }