
















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "../question";
import { QuestionRankingModel } from "survey-core";

@Component
export class Ranking extends QuestionVue<QuestionRankingModel> {}

Vue.component("survey-ranking", Ranking);
export default Ranking;
