var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { class: _vm.question.cssClasses.root },
    _vm._l(_vm.question.getRows(), function (row, rowindex) {
      return _c(
        "tr",
        {
          key: _vm.question.inputId + "rowkey" + rowindex,
          class: _vm.question.cssClasses.row,
        },
        [
          _vm._l(row, function (item) {
            return [
              _c(
                "td",
                {
                  key: "label" + item.editor.id,
                  class: _vm.question.getItemTitleCss(),
                },
                [
                  item.editor.isRequireTextBeforeTitle ||
                  item.editor.isRequireTextOnStart
                    ? _c(
                        "span",
                        { class: _vm.question.cssClasses.requiredText },
                        [_vm._v(_vm._s(item.editor.requiredText))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("survey-string", { attrs: { locString: item.locTitle } }),
                  _vm._v(" "),
                  item.editor.isRequireTextAfterTitle
                    ? _c(
                        "span",
                        { class: _vm.question.cssClasses.requiredText },
                        [_vm._v(_vm._s(item.editor.requiredText))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  key: item.editor.id,
                  attrs: { css: _vm.question.getItemCss() },
                },
                [
                  _vm.hasErrorsOnTop
                    ? _c("survey-errors", {
                        attrs: { element: item.editor, location: "top" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(_vm.getComponentName(item.editor), {
                    tag: "component",
                    attrs: { question: item.editor },
                  }),
                  _vm._v(" "),
                  _vm.hasErrorsOnBottom
                    ? _c("survey-errors", {
                        attrs: { element: item.editor, location: "bottom" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }