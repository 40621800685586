var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.item.getActionBarItemActiveCss(),
      attrs: {
        type: "button",
        disabled: _vm.item.enabled !== undefined && !_vm.item.enabled,
        title: _vm.item.tooltip || _vm.item.title,
        tabindex: _vm.item.disableTabStop ? -1 : 0,
      },
      on: {
        click: function () {
          _vm.item.action()
        },
        keyup: function (evt) {
          evt.stopPropagation()
        },
      },
    },
    [
      _vm.item.iconName
        ? _c("sv-svg-icon", {
            staticClass: "sv-action-bar-item__icon",
            attrs: { iconName: _vm.item.iconName, size: _vm.item.iconSize },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.showTitle === undefined ||
      _vm.item.showTitle ||
      !_vm.item.iconName
        ? _c("span", { class: _vm.item.getActionBarItemCss() }, [
            _vm._v(_vm._s(_vm.item.title)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }