var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.question.isReadOnlyRenderDiv()
      ? _c("textarea", {
          class: _vm.question.cssClasses
            ? _vm.question.getControlClass()
            : "panel-comment-root",
          style: { resize: _vm.question.autoGrow ? "none" : "both" },
          attrs: {
            type: "text",
            readonly: _vm.question.isInputReadOnly,
            disabled: _vm.question.isInputReadOnly,
            id: _vm.question.inputId,
            maxlength: _vm.question.getMaxLength(),
            cols: _vm.question.cols,
            rows: _vm.question.rows,
            placeholder: _vm.question.renderedPlaceHolder,
            "aria-required": _vm.question.ariaRequired,
            "aria-label": _vm.question.ariaLabel,
            "aria-invalid": _vm.question.ariaInvalid,
            "aria-describedby": _vm.question.ariaDescribedBy,
          },
          domProps: { value: _vm.question.value },
          on: {
            change: _vm.change,
            input: function (e) {
              _vm.question.onInput(e)
            },
            keydown: function (e) {
              _vm.question.onKeyDown(e)
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.isReadOnlyRenderDiv()
      ? _c("div", [_vm._v(_vm._s(_vm.question.value))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }