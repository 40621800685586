var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: _vm.getRootClass(_vm.question) }, [
        _c(
          "fieldset",
          { attrs: { role: "radiogroup" } },
          [
            _c("legend", {
              attrs: { "aria-label": _vm.question.locTitle.renderedHtml },
            }),
            _vm._v(" "),
            _vm._l(_vm.question.visibleRateValues, function (item, index) {
              return _c(
                "label",
                { key: item.value, class: _vm.question.getItemClass(item) },
                [
                  _c("input", {
                    staticClass: "sv-visuallyhidden",
                    attrs: {
                      type: "radio",
                      name: _vm.question.name,
                      id: _vm.question.inputId + "_" + index,
                      disabled: _vm.question.isInputReadOnly,
                      "aria-required": _vm.question.ariaRequired,
                      "aria-label": _vm.question.ariaLabel,
                      "aria-invalid": _vm.question.ariaInvalid,
                      "aria-describedby": _vm.question.ariaDescribedBy,
                    },
                    domProps: { value: item.value },
                    on: {
                      click: function (e) {
                        return _vm.question.setValueFromClick(e.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  index === 0
                    ? _c(
                        "span",
                        { class: _vm.question.cssClasses.minText },
                        [
                          _c("survey-string", {
                            attrs: {
                              locString: _vm.question.locMinRateDescription,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    { class: _vm.question.cssClasses.itemText },
                    [
                      _c("survey-string", {
                        attrs: { locString: item.locText },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  index === _vm.question.visibleRateValues.length - 1
                    ? _c(
                        "span",
                        { class: _vm.question.cssClasses.maxText },
                        [
                          _c("survey-string", {
                            attrs: {
                              locString: _vm.question.locMaxRateDescription,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("survey-other-choice", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.question.hasOther,
            expression: "question.hasOther",
          },
        ],
        class: _vm.question.cssClasses.other,
        attrs: { question: _vm.question },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }