var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.root }, [
    !_vm.question.isReadOnly
      ? _c("input", {
          class: _vm.question.cssClasses.fileInput,
          attrs: {
            type: "file",
            id: _vm.question.inputId,
            "aria-required": _vm.question.ariaRequired,
            "aria-label": _vm.question.ariaLabel,
            "aria-invalid": _vm.question.ariaInvalid,
            "aria-describedby": _vm.question.ariaDescribedBy,
            multiple: _vm.question.allowMultiple ? "multiple" : undefined,
            title: _vm.question.inputTitle,
            accept: _vm.question.acceptedTypes,
          },
          on: { change: _vm.question.doChange },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.isReadOnly
      ? _c("input", {
          class: _vm.question.getReadOnlyFileCss(),
          staticStyle: { color: "transparent" },
          attrs: {
            type: "file",
            disabled: "",
            placeholder: _vm.question.title,
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.question.getFileDecoratorCss(),
        on: {
          drop: _vm.question.onDrop,
          dragover: _vm.question.onDragOver,
          dragleave: _vm.question.onDragLeave,
        },
      },
      [
        _c("span", { class: _vm.question.cssClasses.dragAreaPlaceholder }, [
          _vm._v(_vm._s(_vm.question.dragAreaPlaceholder)),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.question.cssClasses.wrapper }, [
          _c(
            "label",
            {
              class: _vm.question.getChooseFileCss(),
              attrs: {
                role: "button",
                for: _vm.question.inputId,
                "aria-label": _vm.question.chooseButtonCaption,
              },
            },
            [_vm._v(_vm._s(_vm.question.chooseButtonCaption))]
          ),
          _vm._v(" "),
          _vm.question.isEmpty()
            ? _c("span", { class: _vm.question.cssClasses.noFileChosen }, [
                _vm._v(_vm._s(_vm.question.noFileChosenCaption)),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    !_vm.question.isReadOnly && !_vm.question.isEmpty()
      ? _c(
          "button",
          {
            class: _vm.question.cssClasses.removeButton,
            attrs: { type: "button" },
            on: { click: _vm.question.doClean },
          },
          [_vm._v("\n    " + _vm._s(_vm.question.cleanButtonCaption) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.question.isEmpty()
      ? _c(
          "div",
          { class: _vm.question.cssClasses.fileList },
          _vm._l(_vm.question.previewValue, function (val, index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: val,
                    expression: "val",
                  },
                ],
                key: _vm.question.inputId + "_" + index,
                class: _vm.question.cssClasses.preview,
              },
              [
                val.name
                  ? _c("div", { class: _vm.question.cssClasses.fileSign }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: val.content,
                            title: val.name,
                            download: val.name,
                            width: _vm.question.imageWidth,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.question.doDownloadFile($event, val)
                            },
                          },
                        },
                        [_vm._v(_vm._s(val.name))]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.question.canPreviewImage(val)
                  ? _c("img", {
                      attrs: {
                        src: val.content,
                        height: _vm.question.imageHeight,
                        width: _vm.question.imageWidth,
                        alt: "File preview",
                      },
                    })
                  : _c("img", {
                      class: _vm.question.cssClasses.defaultImage,
                      attrs: {
                        height: _vm.question.imageHeight,
                        width: _vm.question.imageWidth,
                      },
                    }),
                _vm._v(" "),
                val.name && !_vm.question.isReadOnly
                  ? _c(
                      "div",
                      {
                        class: _vm.question.cssClasses.removeFileButton,
                        on: {
                          click: function ($event) {
                            return _vm.question.doRemoveFile(val)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { class: _vm.question.cssClasses.removeFile },
                          [_vm._v(_vm._s(_vm.question.removeFileCaption))]
                        ),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            class: _vm.question.cssClasses.removeFileSvg,
                            attrs: { viewBox: "0 0 16 16" },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M8,2C4.7,2,2,4.7,2,8s2.7,6,6,6s6-2.7,6-6S11.3,2,8,2z M11,10l-1,1L8,9l-2,2l-1-1l2-2L5,6l1-1l2,2l2-2l1,1L9,8 L11,10z",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                val.name
                  ? _c(
                      "div",
                      { class: _vm.question.cssClasses.fileSignBottom },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: val.content,
                              title: val.name,
                              download: val.name,
                              width: _vm.question.imageWidth,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.question.doDownloadFile($event, val)
                              },
                            },
                          },
                          [_vm._v(_vm._s(val.name))]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.question.isReadOnly && !_vm.question.isEmpty()
      ? _c(
          "button",
          {
            class: _vm.question.cssClasses.removeButtonBottom,
            attrs: { type: "button" },
            on: { click: _vm.question.doClean },
          },
          [_vm._v("\n    " + _vm._s(_vm.question.cleanButtonCaption) + "\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }