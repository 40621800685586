var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.root },
    [
      _c("hr", { class: _vm.question.cssClasses.separatorV2 }),
      _vm._v(" "),
      _vm.question.isProgressTopShowing
        ? _c("survey-paneldynamicprogress", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.renderedPanels, function (panel) {
        return [
          _c(
            "div",
            { key: panel.id, class: _vm.question.getPanelWrapperCss() },
            [
              _c("survey-panel", { attrs: { question: panel, css: _vm.css } }),
              _vm._v(" "),
              _c("survey-paneldynamicremove", {
                attrs: { question: _vm.question, panel: panel },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", {
            key: "separator" + panel.id,
            class: _vm.question.cssClasses.separator,
          }),
        ]
      }),
      _vm._v(" "),
      _vm.question.isProgressBottomShowing
        ? _c("survey-paneldynamicprogress", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.isRenderModeList && _vm.question.canAddPanel
        ? _c(
            "button",
            {
              class: _vm.question.getAddButtonCss(),
              attrs: { type: "button" },
              on: { click: _vm.addPanelClick },
            },
            [
              _c("span", { class: _vm.question.cssClasses.buttonAddText }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.question.panelAddText) + "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("survey-paneldynamicprogress-v2", {
        attrs: { question: _vm.question },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }