var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.element.hasVisibleErrors,
          expression: "element.hasVisibleErrors",
        },
      ],
      class: _vm.element.cssError,
      attrs: {
        role: "alert",
        "aria-live": "polite",
        id: _vm.element.id + "_errors",
      },
    },
    _vm._l(_vm.element.errors, function (error, index) {
      return _c("div", { key: "error_" + index }, [
        _c("span", {
          class: _vm.element.cssClasses
            ? _vm.element.cssClasses.error.icon
            : "panel-error-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            class: _vm.element.cssClasses
              ? _vm.element.cssClasses.error.item
              : "panel-error-item",
          },
          [_c("survey-string", { attrs: { locString: error.locText } })],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }