
































































import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { PopupBaseViewModel, createPopupModalViewModel, settings } from "survey-core";
import { BaseVue } from "../../base";
@Component
export class PopupContainer extends BaseVue {
  @Prop() model: PopupBaseViewModel;
  private prevIsVisible: boolean;
  protected getModel() {
    return this.model;
  }
  clickInside(event: any) {
    event.stopPropagation();
  }
  onUpdated() {
    if (!this.prevIsVisible && this.model.isVisible) {
      this.model.updateOnShowing();
    }
    this.prevIsVisible = this.model.isVisible;
  }
}
export function showModal(
  componentName: string,
  data: any,
  onApply: () => boolean,
  onCancel?: () => void,
  cssClass?: string,
  title?: string
) {
  const popupViewModel: PopupBaseViewModel = createPopupModalViewModel(
    componentName,
    data,
    onApply,
    onCancel,
    () => {
      popup.$destroy();
      popupViewModel.destroyPopupContainer();
    },
    undefined,
    cssClass,
    title
  );
  const popup = new PopupContainer({
    el: popupViewModel.container.appendChild(document.createElement("div")),
    propsData: { model: popupViewModel },
  });
  popupViewModel.model.isVisible = true;
}
settings.showModal = showModal;
Vue.component("sv-popup-container", PopupContainer);
export default PopupContainer;
