var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.canRemovePanel && !_vm.question.isReadOnly
    ? _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isCollapsed,
              expression: "!isCollapsed",
            },
          ],
          class: _vm.question.getPanelRemoveButtonCss(),
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.removePanelClick(_vm.panel)
            },
          },
        },
        [
          _c("span", { class: _vm.question.cssClasses.buttonRemoveText }, [
            _vm._v("\n    " + _vm._s(_vm.question.panelRemoveText) + "\n  "),
          ]),
          _vm._v(" "),
          _c("span", { class: _vm.question.cssClasses.iconRemove }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }