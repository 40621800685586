










import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { Action, Base } from "survey-core";
import BaseVue from "../../base";
@Component
export class ActionViewModel extends BaseVue {
  @Prop() item: Action;
  constructor(props: any) {
    super(props);
  }
  getModel(): Base {
    return this.item;
  }
  getComponentName() {
    return this.item.component || "sv-action-bar-item";
  }
}
Vue.component("sv-action", ActionViewModel);
export default ActionViewModel;
