var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.page.cssClasses.page.root },
    [
      _c("survey-element-title", {
        attrs: { element: _vm.page, css: _vm.css },
      }),
      _vm._v(" "),
      _vm.showDescription
        ? _c(
            "div",
            { class: _vm.page.cssClasses.page.description },
            [
              _c("survey-string", {
                attrs: { locString: _vm.page.locDescription },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.rows, function (row, index) {
        return row.visible
          ? _c(
              "div",
              { key: _vm.page.id + "_" + index, class: row.getRowCss() },
              [
                _c("survey-row", {
                  attrs: { row: row, survey: _vm.survey, css: _vm.css },
                }),
              ],
              1
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }