var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: this.cssClass, staticStyle: { clear: "both" } }, [
    _c("div", { class: _vm.question.cssClasses.progressContainer }, [
      _c("div", { attrs: { title: _vm.question.panelPrevText } }, [
        _c(
          "svg",
          {
            class: _vm.question.getPrevButtonCss(),
            attrs: { viewBox: "0 0 10 10" },
            on: { click: _vm.prevPanelClick },
          },
          [_c("polygon", { attrs: { points: "2,2 0,4 5,9 10,4 8,2 5,5 " } })]
        ),
      ]),
      _vm._v(" "),
      _vm.question.isRangeShowing
        ? _c("div", { class: _vm.question.cssClasses.progress }, [
            _c("div", {
              class: _vm.question.cssClasses.progressBar,
              style: { width: _vm.progress },
              attrs: { role: "progressbar" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { title: _vm.question.panelNextText } }, [
        _c(
          "svg",
          {
            class: _vm.question.getNextButtonCss(),
            attrs: { viewBox: "0 0 10 10" },
            on: { click: _vm.nextPanelClick },
          },
          [_c("polygon", { attrs: { points: "2,2 0,4 5,9 10,4 8,2 5,5 " } })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.question.canAddPanel
      ? _c(
          "button",
          {
            class: _vm.question.getAddButtonCss(),
            attrs: { type: "button" },
            on: { click: _vm.addPanelClick },
          },
          [
            _c("span", { class: _vm.question.cssClasses.buttonAddText }, [
              _vm._v("\n      " + _vm._s(_vm.question.panelAddText) + "\n    "),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.question.cssClasses.progressText }, [
      _vm._v("\n    " + _vm._s(_vm.question.progressText) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }