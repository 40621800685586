var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey.renderedHasHeader
    ? _c("div", { class: _vm.survey.css.header }, [
        _vm.survey.isLogoBefore
          ? _c("div", { class: _vm.survey.logoClassNames }, [
              _c("img", {
                class: _vm.survey.css.logoImage,
                style: { objectFit: _vm.survey.logoFit },
                attrs: {
                  src: _vm.survey.locLogo.renderedHtml,
                  width: _vm.survey.logoWidth
                    ? _vm.survey.logoWidth
                    : undefined,
                  height: _vm.survey.logoHeight
                    ? _vm.survey.logoHeight
                    : undefined,
                  alt: _vm.survey.locTitle.renderedHtml,
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.survey.renderedHasTitle
          ? _c(
              "div",
              {
                class: _vm.survey.css.headerText,
                style: { maxWidth: _vm.survey.titleMaxWidth },
              },
              [
                _c("survey-element-title", {
                  attrs: { element: _vm.survey, css: _vm.survey.css },
                }),
                _vm._v(" "),
                _c(
                  "h5",
                  { class: _vm.survey.css.description },
                  [
                    _c("survey-string", {
                      attrs: { locString: _vm.survey.locDescription },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.survey.isLogoAfter
          ? _c("div", { class: _vm.survey.logoClassNames }, [
              _c("img", {
                class: _vm.survey.css.logoImage,
                style: { objectFit: _vm.survey.logoFit },
                attrs: {
                  src: _vm.survey.locLogo.renderedHtml,
                  width: _vm.survey.logoWidth
                    ? _vm.survey.logoWidth
                    : undefined,
                  height: _vm.survey.logoHeight
                    ? _vm.survey.logoHeight
                    : undefined,
                  alt: _vm.survey.locTitle.renderedHtml,
                },
              }),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }