var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.question.getItemClass(_vm.item),
      attrs: { tabindex: "0", "data-sv-drop-target-ranking-item": _vm.index },
      on: {
        keydown: function (event) {
          _vm.question.handleKeydown.call(_vm.question, event, _vm.item)
        },
        pointerdown: function (event) {
          _vm.question.handlePointerDown.call(
            _vm.question,
            event,
            _vm.item,
            event.currentTarget
          )
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { outline: "none" }, attrs: { tabindex: "-1" } },
        [
          _c("div", { class: _vm.cssClasses.itemGhostNode }),
          _vm._v(" "),
          _c("div", { class: _vm.cssClasses.itemContent }, [
            _c("div", { class: _vm.cssClasses.itemIconContainer }, [
              _c(
                "svg",
                {
                  class: _vm.question.getIconHoverCss(),
                  attrs: {
                    width: "10",
                    height: "16",
                    viewBox: "0 0 10 16",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  class: _vm.question.getIconFocusCss(),
                  attrs: {
                    width: "10",
                    height: "24",
                    viewBox: "0 0 10 24",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", { attrs: { d: "M10 5L5 0L0 5H4V9H6V5H10Z" } }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M6 19V15H4V19H0L5 24L10 19H6Z" } }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.cssClasses.itemIndex }, [
              _vm._v(_vm._s(_vm.indexText)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.cssClasses.controlLabel },
              [_c("survey-string", { attrs: { locString: _vm.text } })],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }