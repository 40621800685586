var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.element.isTitleOwner
        ? _c("survey-string", { attrs: { locString: _vm.element.locTitle } })
        : _vm._e(),
      _vm._v(" "),
      _vm.element.isTitleOwner
        ? _c(
            "div",
            [
              _vm.element.isRequireTextOnStart
                ? _c(
                    "span",
                    {
                      class: _vm.element.cssClasses.requiredText,
                      attrs: { "aria-hidden": true },
                    },
                    [_vm._v(_vm._s(_vm.element.requiredText))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.element.no
                ? _c(
                    "span",
                    {
                      class: _vm.element.cssClasses.number,
                      staticStyle: { position: "static" },
                      attrs: { "aria-hidden": true },
                    },
                    [_vm._v(_vm._s(_vm.element.no))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.element.isRequireTextBeforeTitle
                ? _c(
                    "span",
                    {
                      class: _vm.element.cssClasses.requiredText,
                      attrs: { "aria-hidden": true },
                    },
                    [_vm._v(_vm._s(_vm.element.requiredText))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("survey-string", {
                attrs: { locString: _vm.element.locTitle },
              }),
              _vm._v(" "),
              _vm.element.isRequireTextAfterTitle
                ? _c(
                    "span",
                    {
                      class: _vm.element.cssClasses.requiredText,
                      attrs: { "aria-hidden": true },
                    },
                    [_vm._v(_vm._s(_vm.element.requiredText))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }