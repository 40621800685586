



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ListModel } from "survey-core";
import { BaseVue } from "../../base";

export * from "./list-item.vue";

@Component
export class List extends BaseVue {
  @Prop() model: ListModel;
  constructor() {
    super();
  }
  getModel() {
    return this.model;
  }
  change(event: any) {
    this.model.filteredText = event.target.value;
  }
  keyup(event: any) {
    this.model.filteredText = event.target.value;
  }
}

Vue.component("sv-list", List);
export default List;
